import { cn, cva, type VariantProps } from "@nephroflow/design-system/styling/utils";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Slot } from "@radix-ui/react-slot";
import * as React from "react";

import { Button } from "./button";

const DialogTrigger = DialogPrimitive.Trigger;
const DialogClose = DialogPrimitive.Close;

const SetDialogOpenContext = React.createContext<React.Dispatch<React.SetStateAction<boolean>>>(() => {});

function useCloseDialog() {
  const setOpen = React.useContext(SetDialogOpenContext);
  return React.useCallback(() => setOpen(false), [setOpen]);
}

const DialogRoot = ({
  children,
  defaultOpen = false,
  ...props
}: Omit<React.ComponentPropsWithoutRef<typeof DialogPrimitive.Root>, "open" | "onOpenChange" | "modal">) => {
  const [open, setOpen] = React.useState(defaultOpen);
  return (
    <DialogPrimitive.Root {...props} open={open} onOpenChange={setOpen} modal>
      <SetDialogOpenContext.Provider value={setOpen}>{children}</SetDialogOpenContext.Provider>
    </DialogPrimitive.Root>
  );
};
DialogRoot.displayName = "DialogRoot";

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    className={cn(
      "motion-safe:data-[state=open]:duration-200 motion-safe:data-[state=open]:animate-in motion-safe:data-[state=open]:fade-in",
      "motion-safe:data-[state=closed]:duration-200 motion-safe:data-[state=closed]:animate-out motion-safe:data-[state=closed]:fade-out",
      "fixed inset-0 z-50 bg-blue-150/30",
      className,
    )}
    {...props}
    ref={ref}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const dialogVariants = cva(
  [
    "fixed left-1/2 top-1/2 z-50 flex max-h-screen -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center rounded bg-white px-6",
    "motion-safe:data-[state=open]:duration-400 motion-safe:data-[state=open]:fade-in motion-safe:data-[state=open]:zoom-in motion-safe:data-[state=open]:slide-in-from-top",
    "motion-safe:data-[state=closed]:duration-400 motion-safe:data-[state=closed]:slide-out-top motion-safe:data-[state=closed]:fade-out motion-safe:data-[state=closed]:zoom-out",
  ],
  {
    variants: {
      size: {
        compact: "w-[54rem]",
        default: "w-[73rem]",
        large: "w-[104rem]",
        extraLarge: "w-[148rem]",
      },
    },

    defaultVariants: {
      size: "default",
    },
  },
);

const DialogDialog = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    lightDismiss?: boolean;
  } & VariantProps<typeof dialogVariants>
>(
  (
    { className, children, lightDismiss = false, size = "default", onInteractOutside, onEscapeKeyDown, ...props },
    ref,
  ) => {
    return (
      <DialogPrimitive.Portal>
        <DialogOverlay />
        <DialogPrimitive.Content
          ref={ref}
          className={cn(dialogVariants({ size }), className)}
          onInteractOutside={(event) => {
            if (!lightDismiss) event.preventDefault();
            onInteractOutside?.(event);
          }}
          onEscapeKeyDown={(event) => {
            if (!lightDismiss) event.preventDefault();
            onEscapeKeyDown?.(event);
          }}
          {...props}
        >
          {children}
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    );
  },
);
DialogDialog.displayName = DialogPrimitive.Content.displayName;

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "col-start-1 col-end-4 row-start-1 row-end-2 flex pb-4 pt-6 text-2xl font-medium leading-5",
      className,
    )}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

function DialogLayout({ asChild = false, children }: { asChild?: boolean; children: React.ReactNode }) {
  const Comp = asChild ? Slot : "div";
  return (
    <Comp className="grid h-full w-full grid-cols-[11rem_1fr_11rem] grid-rows-[auto_auto_10rem] overflow-hidden">
      {children}
    </Comp>
  );
}

function DialogSecondaryButtonSlot({ className, children }: { className?: string; children: React.ReactNode }) {
  return (
    <DialogClose asChild>
      <div className={cn("col-start-3 col-end-4 row-start-3 row-end-4 flex items-start justify-end pt-4", className)}>
        <Button impact="neutral" importance="secondary">
          {children}
        </Button>
      </div>
    </DialogClose>
  );
}

function DialogPrimaryButtonSlot({ className, children }: { className?: string; children: React.ReactNode }) {
  return (
    <DialogClose asChild>
      <div
        className={cn(
          "col-start-4 col-end-5 row-start-3 row-end-4 flex items-start justify-end pb-3 pl-4 pt-4",
          className,
        )}
      >
        <Button impact="neutral" importance="primary">
          {children}
        </Button>
      </div>
    </DialogClose>
  );
}

function DialogContent({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <div
      className={cn(
        "col-start-1 col-end-5 row-start-2 row-end-3 mr-3 h-80 w-full overflow-x-hidden overflow-y-scroll",
        className,
      )}
    >
      {children}
    </div>
  );
}

export {
  DialogClose,
  DialogContent,
  DialogDialog,
  DialogLayout,
  DialogPrimaryButtonSlot,
  DialogRoot,
  DialogSecondaryButtonSlot,
  DialogTitle,
  DialogTrigger,
  useCloseDialog,
};
